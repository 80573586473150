.divider {
  width: 100%;
  height: 1px;
  border: 0;
  border-top: 1px solid #d7d7d7;
  margin: 15px 0;
}

.header {
  font-weight: 600;
  font-size: 18px;
}

.districtInput {
  input {
    display: none;
  }
}
