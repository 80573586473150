.tableActions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;

  @media screen and (max-width: 500px) {
    justify-content: flex-start;
    flex-direction: column;
  }
}
