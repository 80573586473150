.layout {
  flex: 1;
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  .sideMenu {
    flex: 0 1 auto;
    display: flex;
  }

  .mainContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
  }

  .pageContentContainer {
    flex: 1;
    overflow: auto;
    max-width: 100vw;
    width: 100%;

    .pageContent {
      margin: 20px 35px;

      @media screen and (max-width: 1279px) {
        margin: 20px 20px;
      }
    }
  }
}

.loader {
  flex: 1 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.centeredLayoutMessage {
  flex: 1;
  align-self: center;
  text-align: center;
  font-size: 20px;
}

.fillPage {
  display: flex;
  flex-direction: column;
  flex: 1;
}
