.rowHeader,
.columnHeader {
  background: inherit !important;
  font-weight: bold !important;
}

.table {
  td,
  th {
    text-align: center;
    background: #fff;
  }
}
