.message {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.phoneNumber {
  direction: ltr;
}
