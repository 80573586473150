.myLocationButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 70px;
  background-color: #fff;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  margin-left: -2px;
  background-clip: padding-box;
  cursor: pointer;
}

.myLocationButton:hover {
  background-color: #f4f4f4;
}

.fetchingLocation {
  position: absolute;
  margin-left: 250px;
  width: 200px;
  font-size: 16px;

  span {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    padding: 5px;
    border-radius: 4px;
  }
}
