.healthRiskFilter {
  width: 200px !important;
}

.healtRiskMenuItem {
  font-size: 16px !important;
  width: 100% !important;
  background-color: #fff !important;
  padding: 20px 0 20px 0 !important;
}
