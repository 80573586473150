.chip {
  margin: 4px 2px;
  background-color: #cddde7 !important;
}

.alertNotificationsHeader {
  display: flex;
  align-items: center;
}

.helpIcon {
  margin-left: 10px;
}
