.displayField {
  margin: 10px 0;
}

.label {
  font-weight: bold;
}

.value {
}
