.consentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.consentPaper {
  display: flex;
  align-items: stretch;
  flex: 1 0 0;
  height: 100vh;
  flex-direction: column;
  width: 80%;
  padding: 0 60px;

  @media screen and (max-width: 1279px) {
    width: unset;
    padding: unset;
  }
}

.aboveDocument {
  padding: 20px;
}

.agreementDocumentFrame {
  flex: 1 0 0;
  padding: 20px;
  min-height: 100px;

  .document {
    height: 100%;
  }
}

.belowDocument {
  padding: 20px;
  margin-top: auto;
}
