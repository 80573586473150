.stringKey {
  &:hover {
    background-color: lightcoral;
    cursor: pointer;
  }

  @media screen and (max-width: 1279px) {
    display: none;
  }
}
