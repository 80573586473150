.horizontal {
  flex-direction: row !important;
}

.label {
  margin-bottom: 8px !important;
}

.labelBold {
  margin-bottom: 8px !important;
  font-weight: 600 !important;
}
