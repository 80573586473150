.tableRowActions {
  text-align: right;
  padding-top: 0;
  padding-bottom: 0;
  white-space: nowrap;

  &.rtl {
    text-align: left;
  }
}

.tableRowAction {
  margin-left: 15px;
  display: inline-block;
  color: #454545;
  cursor: pointer;
  opacity: 0.2;
  position: relative;
  font-size: 20px !important;

  &.fetching {
    opacity: 1;

    .icon {
      opacity: 0.5;
    }
  }

  &.alwaysHighlighted {
    opacity: 1;
  }

  &.rtl {
    margin-left: unset;
    margin-right: 15px;
  }
}

tr:hover {
  .tableRowAction {
    opacity: 1;
  }
}

.loader {
  position: absolute;
}

.icon {
  display: inline-block;
}
