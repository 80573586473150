.numberName {
  flex: 2;
}

.numberValue {
  flex: 1;
  text-align: center;
  font-weight: 600;
}

.numberBox {
  display: flex;
}

.card {
  flex: 1;
  min-width: 180px;
  padding: 5px 5px;
  font-size: 16px;
  border-radius: 8px !important;
}
