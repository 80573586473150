.popup {
  max-height: 300px;
  overflow-y: auto;
}

.dataCollectorDetails {
  display: flex;
  align-items: center;
  margin: 10px 0;
  min-width: 200px;

  > span {
    margin-right: 5px;
  }
}

.map {
  height: 500px;
}

.marker_ReportingWithErrors {
  background-color: #d23232 !important;
  color: white;
}

.marker_ReportingCorrectly {
  background-color: #15ab15 !important;
  color: white;
}

.marker_NotReporting {
  background-color: #f1b713 !important;
  color: #525252;
}
