.filterItem {
  min-width: 200px !important;
}

.filters {
  box-shadow: #fff 0px 5px 5px 5px !important;
}

.filterLabel {
  font-weight: 400;
}
