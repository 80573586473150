.stringSwitcher {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-left: 10px;
  margin-bottom: 10px;
  z-index: 9999;

  @media screen and (max-width: 1279px) {
    display: none;
  }
}
