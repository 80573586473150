.loginContent {
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  display: flex;

  @media screen and (min-width: 960px) {
    padding-top: 30px;
  }
}

.paperHeader {
  margin: 60px 0 !important;
}

.loginPaper {
  width: 100%;

  @media screen and (min-width: 960px) {
    width: 750px;
  }
}

.loginPaperContent {
  padding: 50px 20px;

  @media screen and (min-width: 600px) {
    padding: 50px 160px;
  }
}

.forgotPasswordLink {
  text-align: right;
  display: block;
  position: relative;
  top: -20px;
}

.actions {
  margin-top: 40px;
  text-align: right;
}
