.formsActions {
  margin-top: 0;
}

.input {
  margin-top: -30px !important;
}

.inputWarningMsg {
  font-size: 14px;
  color: #f1b713;
}
