.button {
  margin-left: 8px !important;

  @media screen and (max-width: 599px) {
    margin-left: unset !important;
    margin-right: unset !important;
    margin-bottom: 8px !important;
  }
}

.progressIcon {
  position: absolute;
}

.mobile {
  display: none;
}
