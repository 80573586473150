.introduction {
  font-weight: bold;
  font-size: 18px;
}

.form {
  max-width: 1279px;
}

.reportsTitle {
  margin: 20px 0;
  font-weight: bold;
  font-size: 18px;
}
