.phoneInput {
  border: 1px solid #e4e1e0 !important;
  border-radius: 0 !important;
  width: 100% !important;
  height: 38px !important;
}

.container {
  font-family: "Arial" !important;
  font-size: 16px !important;
}

.error {
  border-bottom: 2px solid #f44336;
}

.react-tel-input .special-label {
  display: block !important;
}

.button {
  border: 1px solid #e4e1e0 !important;
  border-radius: 0 !important;
}

.search {
  display: none;
}

.label {
  margin-bottom: 10px;
}
