.legend {
  display: flex;
  padding: 15px 10px;
  justify-content: center;
}

.item {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.icon {
  margin-right: 10px;

  &.rtl {
    margin-right: unset;
    margin-left: 10px;
  }
}
