.loginContent {
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  display: flex;
  padding-top: 30px;

  @media screen and (max-width: 1279px) {
    padding-top: 0;
  }
}

.paperHeader {
  margin: 30px 0 !important;

  @media screen and (max-width: 1279px) {
    font-size: 24px !important;
    margin: 30px 0 !important;
    text-align: center;
  }
}

.loginHeader {
  @media screen and (max-width: 1279px) {
    text-align: center;
    font-size: 18px !important;
  }
}

.loginPaper {
  width: 500px;

  @media screen and (max-width: 1279px) {
    background: none !important;
    width: unset;
  }
}

.loginPaperContent {
  background-color: #fcfcfc;
  padding: 0 20px;
  padding-bottom: 20px;

  @media screen and (min-width: 1280px) {
    padding: 50px;
  }
}

.loginFooter {
  justify-content: center;
  align-items: baseline;
}

.forgotPasswordLink {
  text-align: right;
  display: block;
  margin-top: 5px;
}

.actions {
  margin-top: 40px;
  text-align: right;
}

.loginPageLogo {
  background-color: #fcfcfc;
  text-align: center;

  svg {
    width: 44%;
    margin: 60px 0 100px 0;
    opacity: 1;
    animation: initlogo 1s ease-in-out;

    @media screen and (max-width: 1279px) {
      margin: 20px;
      margin-top: 50px;
    }

    .redFill {
      fill: #f5333f;
    }

    .logoCurve {
      fill: #f5333f;
      opacity: 1;
      animation: showcurve 1.5s linear;
    }

    .animationCurve {
      fill: none;
      stroke: #f5333f;
      stroke-width: 5.30000019;
      stroke-linecap: butt;
      stroke-linejoin: miter;
      stroke-miterlimit: 4;
      stroke-opacity: 1;

      stroke-dasharray: 100 1000;
      stroke-dashoffset: 600;
      animation: swosh 1.5s linear;
    }
  }
}

.supportLogoHeader {
  margin: 40px 30px 10px 30px !important;
}

.supportLogo {
  margin: 15px 30px;
  width: 160px;

  @media screen and (max-width: 599px) {
    height: unset;
    width: 140px;
  }
}

@keyframes initlogo {
  from {
    opacity: 0;
    width: 35%;
  }

  30% {
    opacity: 0;
  }

  to {
    opacity: 1;
    width: 44%;
  }
}

@keyframes swosh {
  to {
    stroke-dashoffset: -600;
  }
}

@keyframes showcurve {
  from {
    opacity: 0;
  }

  62% {
    opacity: 0;
  }

  66% {
    opacity: 1;
  }
}
