.icon_ReportingWithErrors {
  color: #d23232;
  margin-right: 10px;
}

.icon_ReportingCorrectly {
  color: #15ab15;
  margin-right: 10px;
}

.icon_NotReporting {
  color: #f1b713;
  margin-right: 10px;
}

.rtl {
  margin-right: unset;
  margin-left: 10px;
}
