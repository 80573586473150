.fullWidth {
  width: 100%;
}

.formSectionTitle {
  margin: 20px 0 5px 0;
  font-weight: bold;
  font-size: 18px;
}

.correctNegativeMargin {
  margin: 0px !important;
}

.tableActionsContainer {
  width: 100%;
}

.editButton {
  margin-left: auto;
}
