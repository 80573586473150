.progressIcon {
  position: absolute;
}

.wide {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.underline {
  text-decoration: underline !important;
}
