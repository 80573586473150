.recipient {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  flex-wrap: wrap;
}

.recipientName {
  max-width: 200px;
  min-width: 200px;
  font-weight: bold !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.recipientNameSelect {
  font-weight: bold !important;
  margin-right: 30px;
  flex: 1 0 auto;
  min-width: 200px;
  max-width: 200px;

  &.rtl {
    margin-right: unset;
    margin-left: 30px;
  }
}

.organizationField {
  margin-right: auto !important;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
