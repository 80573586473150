.marker {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: #136bb7af;
  transition: transform ease-out 0.5s;
}

.signMarker {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid #fff;
  box-shadow: #949494 3px 0px 0px -1px;
  transition: transform ease-out 0.5s;
}

.multiple {
  box-shadow: #949494 4px 0px 0px -1px, #ffffff 6px 0px 0px -1px, #949494 9px 0px 0px -2px,
}

.text {
  color: #fff;
  font-size: 16px;
  text-shadow: 1px 1px 2px black;
}
