.anonymousHeader {
  padding: 50px 80px;
  padding-bottom: 20px;

  img {
    width: 200px;
  }

  @media screen and (max-width: 1279px) {
    padding: 30px 0 10px;
    text-align: center;
  }
}

.content {
  flex: 1;
  display: flex;
}

.anonymousLayout {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
