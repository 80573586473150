.dropDown {
  min-width: 50px;
}

.pager {
  margin-top: 10px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
